import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { AuthContext, mostraErro } from "../../Context/auth";
import WebPkiService from "../../Pki/webpki.service.js";
import AuthenticationService from "../../Pki/authentication.service.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Login() {
  const { loginCertificado } = useContext(AuthContext);
  const [logarComCertificado, setlogarComCertificado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const webPkiService = new WebPkiService();
  const authenticationService = new AuthenticationService();

  const [selectedCertThumbprint, setSelectedCertThumbprint] = useState("");

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  function mostraErro(texto) {
    const Alerta = withReactContent(Swal);
    return Alerta.fire({
      title: <p>Atenção</p>,
      text: texto,
      icon: "warning",
    });
  }

  function startPKI() {
    setLoading(true);
    webPkiService.init();
    _loadCertificates();
  }

  async function _loadCertificates() {
    setLoading(true);
    await webPkiService
      .loadCertificates()
      .then((res) => {
        setSelectedCertThumbprint(res[0].thumbprint);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function _handleCertSelectionChange(event) {
    setStatus("");
    setSelectedCertThumbprint(event.target.value);
  }

  function cancelButtonHandler() {
    setStatus("");
    setlogarComCertificado(false);
  }

  function refreshButtonHandler() {
    setStatus("");
    _loadCertificates();
  }

  function buttonHandler() {
    setStatus("");
    setlogarComCertificado(true);
    startPKI();
  }

  async function _EntrarComCertificado() {
    setStatus("");
    setLoading(true);
    setTimeout(async () => {
      try {
        const token = await authenticationService.start().catch((er) => {
          setLoading(false);
          setStatus(
            "Erro ao conectar no servidor Web Pki. Tente novamente em alguns instantes."
          );
          console.error(er);
        });

        if (token) {
          const thumbprint = selectedCertThumbprint;
          await webPkiService.signWithRestPki(thumbprint, token);

          const result = await authenticationService.complete(token);
          if (result.isValid) {
            let ResultCpfCnpj = "";
            if (result.certificate.cnpj) {
              ResultCpfCnpj = result.certificate.cnpj;
            } else {
              ResultCpfCnpj = result.certificate.cpf;
            }

            const isLogged = await loginCertificado(
              ResultCpfCnpj,
              mostraErro,
              result.certificate || {}
            );
            if (isLogged) {
              navigate("/notas/nfe");
            } else {
              setLoading(false);
            }
          } else {
            alert(`O certificado não é válido: ${result.validation}`);
          }
        }
      } catch (error) {
        setLoading(false);
        // eslint-disable-next-line
        setStatus(error?.message);
        console.error(error);
      }
    }, 1000);
  }

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative">
        <div
          className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y"
          style={{ backgroundColor: "#8D0000" }}
        >
          <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
            <a href="https://www.consultoriasolucao.com" className="py-9 mb-1">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/img/logo.png")}
                className="h-60px"
              />
            </a>

            <a href="https://www.consultoriasolucao.com">
              <h1 className="fs-1qx pb-2 " style={{ color: "#FFFFFF" }}>
                Solução Sistemas
              </h1>
            </a>
            <h1 className="fs-2qx pb-2 pb-md-5" style={{ color: "#FFFFFF" }}>
              Portal do Contador
            </h1>
            <p className="fw-bold fs-2" style={{ color: "#FFFFFF" }}>
              Tenha acesso ao .xml
              <br />
              dos documentos fiscais emitidos
              <br />
              nos sistemas SCA e Fiscal Fácil
            </p>
            <p className="text-700 fs-1" style={{ color: "#FFFFFF" }}>
              (67) 4042 - 7490
            </p>
          </div>
          <a href="https://www.consultoriasolucao.com">
            <div
              className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-250px"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/img/logo-solucao.png"
                )})`,
              }}
            ></div>
          </a>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row-fluid py-10">
        <div className="d-flex flex-center flex-column flex-column-fluid">
          <div className="w-lg-500px p-10 p-lg-15 mx-auto">
            {!logarComCertificado ? (
              <div className="m-login__signin">
                <form
                  className="form w-100"
                  noValidate="novalidate"
                  id="kt_sign_in_form"
                  action="#"
                >
                  {status && (
                    <div className="mb-lg-15 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        {status}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">Portal do Contador</h1>
                      <div className="text-gray-400 fw-bold fs-4">
                        Faça seu login abaixo
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="button"
                        id="m_login_signin_certificado"
                        className="btn btn-success btn-flex flex-center btn-lg w-100 mb-5"
                        onClick={buttonHandler}
                      >
                        {!loading && (
                          <span className="indicator-label">
                            Logar com certificado digital
                          </span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Por favor, aguarde....
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div className="m-login__certificado" id="m-login__certificado">
                <form
                  className="form w-100"
                  noValidate="novalidate"
                  id="authForm"
                  action="#"
                >
                  {status && (
                    <div className="mb-lg-15 alert alert-danger">
                      <div className="alert-text font-weight-bold">
                        {status}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">Portal do Contador</h1>
                      <div className="text-gray-400 fw-bold fs-4">
                        Escolha o seu certificado digital
                      </div>
                    </div>

                    <div className="fv-row mb-10">
                      {/* Render the token in a hidden input field. */}
                      <input
                        type="hidden"
                        name="token"
                        id="tokenField"
                        value="token"
                      />

                      <label
                        className="form-label fs-6 fw-bolder text-dark"
                        htmlFor="certificateSelect"
                      >
                        Escolha um certificado
                      </label>

                      <select
                        id="certificateSelect"
                        name="certificado"
                        className="form-control-lg selectpicker"
                        style={{ width: "100%" }}
                        value={selectedCertThumbprint}
                        onChange={(e) => _handleCertSelectionChange(e)}
                      ></select>
                    </div>

                    {/* begin::Action */}
                    <div className="text-center">
                      <button
                        type="button"
                        id="m_login_login_certificado_submit"
                        className="btn btn-lg btn-primary w-100 mb-5"
                        disabled={loading}
                        onClick={async () => await _EntrarComCertificado()}
                      >
                        {!loading && (
                          <span className="indicator-label">Logar</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Por favor, aguarde...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>

                      {/* begin::Separator */}
                      <div className="text-center text-muted text-uppercase fw-bolder mb-5">
                        ou
                      </div>
                      {/* end::Separator */}

                      <button
                        type="button"
                        id="m_login_login_certificado_refresh"
                        className="btn btn-success btn-flex flex-center btn-lg w-100 mb-5"
                        disabled={loading}
                        onClick={refreshButtonHandler}
                      >
                        {!loading && (
                          <span className="indicator-label">
                            Recarregar Certificados
                          </span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Por favor, aguarde...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>

                      <button
                        type="button"
                        id="m_login_login_certificado_cancel"
                        className="btn btn-danger btn-flex flex-center btn-lg w-100 mb-5"
                        disabled={loading}
                        onClick={cancelButtonHandler}
                      >
                        <span className="indicator-label">Cancelar</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
